import * as Yup from "yup"

const REQUIRED_MESSAGE = "This field is required"

const profile = {
  UPDATE_PASSWORD: "update-password",
  EDIT_PROFILE: "edit",
}
const addresses = {
  EDIT_ADDRESS: "edit-address",
  ADD_ADDRESS: "add-address",
}

export const formInitialValues = (module, userData, address = "") => {
  switch (module) {
    case profile.UPDATE_PASSWORD:
      return { oldPassword: "", newPassword: "", confirmPassword: "" }

    case profile.EDIT_PROFILE:
      return {
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        mobileNumber: userData?.mobileNumber,
        birthday: {
          month: {
            value: userData?.birthday.month,
            label: userData?.birthday.month,
          },
          date: {
            value: userData?.birthday.date,
            label: userData?.birthday.date,
          },
          year: userData?.birthday.year,
        },
        emergencyContact: {
          name: userData?.emergencyContact?.name,
          mobileNumber: userData?.emergencyContact?.mobileNumber,
          relationship: userData?.emergencyContact?.relationship,
        },
        userData: userData,
      }

    case addresses.EDIT_ADDRESS:
    case addresses.ADD_ADDRESS:
      return {
        index: address?.index,
        address: {
          addressType:
            address?.type?.replace(/^\w/, (c) => c.toUpperCase()) || "",
          streetAddress: address?.streetAddress || "",
          primary: address?.primary || false,
          province: address?.province
            ? {
                value: address?.province || "",
                label: address?.province || "",
              }
            : null,
          city: address?.city
            ? {
                value: address?.city || "",
                label: address?.city || "",
              }
            : null,
        },
      }
    default:
      return {}
  }
}

export const profileFormSchema = (module) => {
  switch (module) {
    case profile.UPDATE_PASSWORD:
      return updatePasswordSchema

    case profile.EDIT_PROFILE:
      return editProfileSchema

    case addresses.ADD_ADDRESS:
    case addresses.EDIT_ADDRESS:
      return addressValidationSchema
    default:
      return null
  }
}

const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(REQUIRED_MESSAGE),
  newPassword: Yup.string()
    .required(REQUIRED_MESSAGE)
    .min(7, "Your password must be at least seven characters long.")
    .max(80, "Your password must not exceed 80 characters in length.")
    .matches(
      /(?=.*[a-z])/,
      "Your password must contain at least one lowercase character."
    )
    .matches(
      /(?=.*[A-Z])/,
      "Your password must contain at least one uppercase character."
    )
    .matches(/(?=.*[0-9])/, "Your password must contain at least one number.")
    .matches(
      /(?=.*[!|@|#|$|%|&|_|+|=|.|-])/,
      "Your password must contain at least one of these special characters: !@#$%&_-+=."
    )
    .matches(
      /^[a-zA-Z0-9!@#$%&_+=.-]{7,}$/,
      "Your password contains an invalid special character. Please use any from the following only: !@#$%&_-+=."
    ),
  confirmPassword: Yup.string()
    .required(REQUIRED_MESSAGE)
    .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
})

const editProfileSchema = Yup.object().shape({
  firstName: Yup.string().required(REQUIRED_MESSAGE),
  lastName: Yup.string().required(REQUIRED_MESSAGE),
  mobileNumber: Yup.string()
    .min(11, "Please input a valid mobile number in this format: 09991234567")
    .max(11, "Please input a valid mobile number in this format: 09991234567")
    .matches(
      /^09[0-9]{9}$/,
      "Please input a valid mobile number in this format: 09991234567"
    )
    .required(REQUIRED_MESSAGE),
  emergencyContact: Yup.object().shape({
    name: Yup.string().required(REQUIRED_MESSAGE),
    mobileNumber: Yup.string().required(REQUIRED_MESSAGE),
    relationship: Yup.string().required(REQUIRED_MESSAGE),
  }),
})

const addressValidationSchema = Yup.object().shape({
  address: Yup.object().shape({
    addressType: Yup.string().required(REQUIRED_MESSAGE),
    streetAddress: Yup.string().required(REQUIRED_MESSAGE),
    city: Yup.object()
      .shape({
        label: Yup.string().required(REQUIRED_MESSAGE),
        value: Yup.string().required(REQUIRED_MESSAGE),
      })
      .nullable()
      .required(REQUIRED_MESSAGE),
    province: Yup.object().shape({
      label: Yup.string().required(REQUIRED_MESSAGE),
      value: Yup.string().required(REQUIRED_MESSAGE),
    }),
  }),
})
