export const profileFormFields = (values, setFieldValue) => {
  return [
    {
      type: "password",
      name: "oldPassword",
      label: "Current Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "password",
      name: "newPassword",
      label: "New Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "password",
      name: "confirmPassword",
      label: "Confirm Password",
      placeholder: "********",
      inclusions: ["update-password"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "firstName",
      label: "First Name",
      placeholder: "Juan",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "lastName",
      label: "Last Name",
      placeholder: "Dela Cruz",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "mobileNumber",
      label: "Mobile Number",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    // {
    //   type: "text",
    //   name: "email",
    //   label: "Work Email",
    //   placeholder: "jdelacruz@gmail.com",
    //   inclusions: ["edit"],
    //   disabled: true,
    //   isFollowUpQuestion: false,
    //   isRequired: true,
    // },

    {
      type: "text",
      name: "emergencyContact.name",
      label: "Emergency Contact Name",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },

    {
      type: "text",
      name: "emergencyContact.mobileNumber",
      label: "Emergency Contact Mobile Number",
      placeholder: "09123456789",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    {
      type: "text",
      name: "emergencyContact.relationship",
      label: "Relationship to employee",
      placeholder: "Mother",
      inclusions: ["edit"],
      isFollowUpQuestion: false,
      isRequired: true,
    },
    // {
    //   type: "date",
    //   name: "birthday",
    //   label: "Birthday",
    //   inclusions: ["edit"],
    //   values: values?.birthday,
    //   isRequired: true,
    // },
    {
      type: "address",
      fieldNames: {
        // addressType: `address.addressType`,
        streetAddress: `address.streetAddress`,
        city: `address.city`,
        province: `address.province`,
      },
      values: values,
      setFieldValue: setFieldValue,
      inclusions: ["add-address", "edit-address"],
      isNationwide: true,
      isRequired: true,
    },
  ]
}
